import type { CustomerInterface } from './Customer.interface'

export enum UserProviderEnum {
  HARKLINIKKEN = 'HARKLINIKKEN',
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK'
}
export enum UserLoginMethodEnum {
  USERNAME_PASSWORD = 'USERNAME_PASSWORD',
  OTP = 'OTP',
  SOCIAL = 'SOCIAL',
  PASSWORDLESS = 'PASSWORDLESS',
  SHOPIFY = 'SHOPIFY'
}

export type UserAuthMode = 'credentials' | 'otp' | 'passwordless' | 'oauth' | 'shopify'
export type UserAuthPasswordMode = 'credentials' | 'shopify'

export interface UserInterface {
  customer_id?: string
  email?: string
  name?: string
  role?: string
  shop_url?: string
  customer_shop_id?: string
  provider?: UserProviderEnum
  verified?: boolean
  multipass_identifier?: string
  multipass_token?: string
  customer?: CustomerInterface
  customers?: CustomerInterface[]
}
